import './App.css';
import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';

function App() {
  const getAuthCookie = () => {
    return Cookies.get("user_token");
  };

  const userAuthToken = getAuthCookie();
  const [stats, setStats] = useState([]);
   useEffect(() => {
      fetch(
        'https://api.kitajaga.xyz/ktbslifemembership/private/v1/status-stg', {
          headers: new Headers({
            'Authorization': 'Bearer ' + userAuthToken
          })
        })
         .then((response) => response.json())
         .then((data) => {
            setStats(data.data);
         })
         .catch((err) => {
            console.log(err.message);
         });
  }, []);
  return (
    <div className="App">
      <header className="App-header">
      <div>
        <p>Kitabisa.com User Auth Token: {userAuthToken}</p>
        <br />
        <p>Kitabisa Life Status: </p>
        <div className="posts-container">
          {stats.map((stat) => {
            return (
            <div key="kitabisa">
            <h2 className="stat-status">{stat.status}</h2>
            <p className="stat-body">Period start {stat.period_start_at}, end {stat.period_end_at}</p>
            <p className="stat-body">Grace Period start {stat.grace_period_start_at}, end {stat.grace_period_end_at}</p>
            </div>
            )
          })}
          
        </div>
      </div>
      </header>
    </div>
  );
}

export default App;
